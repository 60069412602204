import { CareType } from '@models/common/care-type.model';
import { Catalog } from '@models/content/catalog/catalog.model';
import { CatalogFile } from '@models/content/catalog/catalog-file.model';
import { Category } from '@models/content/catalog/category.model';
import { Collection } from '@models/collection/collection';
import { Community } from '@models/communities/community.model';
import { Corporation } from '@models/corporations/corporation.model';
import { DigitalSignageDevice } from '@models/digital-signage/digital-signage-device.model';
import { DigitalSignageDeviceSettings } from '@models/digital-signage/digital-signage-device-settings.model';
import { DigitalSignageGroup } from '@models/digital-signage/digital-signage-group.model';
import { DigitalSignageSchedule } from '@models/digital-signage/digital-signage-schedule.model';
import { DigitalSignageScreen } from '@models/digital-signage/digital-signage-screen.model';
import { DigitalSignageTeam } from '@models/digital-signage/digital-signage-team.model';
import { DigitalSignageTemplate } from '@models/digital-signage/digital-signage-template.model';
import { DigitalSignageTemplateGroup } from '@models/digital-signage/digital-signage-template-group.model';
import { Dimension } from '@models/calendar/dimension.model';
import { InjectionToken } from '@angular/core';
import { Investor } from '@models/common/investor.model';
import { Media } from '@models/media/media.model';
import { ModelService } from '@services/common/model.service';
import { Problem } from '@models/interviews/problem.model';
import { Region } from '@models/calendar/Region.model';
import { SurveySlide } from '@models/feed-back/survey-slide.model';
import { User } from '@models/account/user.model';

// #region Content Platform

export const CatalogModelService = new InjectionToken<ModelService<Catalog>>(
  'CatalogModelService'
);
// #endregion

export const ContentCategoriesModelService = new InjectionToken<
  ModelService<Category[]>
>('ContentCategoriesModelService');

export const ContentFavoritesModelService = new InjectionToken<
  ModelService<Catalog[]>
>('ContentFavoritesModelService');

export const ContentFilesModelService = new InjectionToken<
  ModelService<CatalogFile[]>
>('ContentFilesModelService');
// #endregion

// #region Corporations

export const CorporationsModelService = new InjectionToken<
  ModelService<Corporation[]>
>('CorporationsModelService');

export const ICorporationModelService = new InjectionToken<
  ModelService<Corporation>
>('CorporationModelService');

export const DimensionsModelService = new InjectionToken<
  ModelService<Dimension[]>
>('DimensionsModelService');
// #endregion

export const CommunityService = new InjectionToken<ModelService<Community>>(
  'ContentCommunityService'
);
export const ICommunityService = new InjectionToken<ModelService<Community>>(
  'ICommunityService'
);
export const AuthService = new InjectionToken<ModelService<User>>(
  'AuthService'
);

export const CommunitiesService = new InjectionToken<ModelService<Community[]>>(
  'CommunitiesService'
);

export const CareTypesService = new InjectionToken<ModelService<CareType[]>>(
  'CareTypesService'
);
// #region Digital Signage
export const DsTemplateGroup = new InjectionToken<
  ModelService<DigitalSignageTemplateGroup>
>('DigitalSignageTemplateGroup');
export const DsTemplateGroups = new InjectionToken<
  ModelService<DigitalSignageTemplateGroup[]>
>('DigitalSignageTemplateGroups');

export const RegionsService = new InjectionToken<ModelService<Region[]>>(
  'RegionsService'
);
export const DsTemplate = new InjectionToken<
  ModelService<DigitalSignageTemplate>
>('DigitalSignageTemplate');

export const InvestorsService = new InjectionToken<ModelService<Investor[]>>(
  'InvestorsService'
);
export const DsGroups = new InjectionToken<ModelService<DigitalSignageGroup[]>>(
  'DigitalSignageGroups'
);

export const SurveySlideService = new InjectionToken<ModelService<SurveySlide>>(
  'SurveySlideService'
);
export const DsGroup = new InjectionToken<ModelService<DigitalSignageGroup>>(
  'DigitalSignageGroup'
);

export const DsGroupEdited = new InjectionToken<
  ModelService<DigitalSignageGroup>
>('DigitalSignageGroupEdited');
export const DsScreen = new InjectionToken<ModelService<DigitalSignageScreen>>(
  'DigitalSignageScreen'
);
export const DsSchedules = new InjectionToken<
  ModelService<DigitalSignageSchedule[]>
>('DigitalSignageSchedules');
export const DsSchedule = new InjectionToken<
  ModelService<DigitalSignageSchedule>
>('DigitalSignageSchedule');
export const DsDevices = new InjectionToken<
  ModelService<DigitalSignageDevice[]>
>('DigitalSignageDevices');
export const DsDevice = new InjectionToken<ModelService<DigitalSignageDevice>>(
  'DigitalSignageDevice'
);
export const DsDeviceSettings = new InjectionToken<
  ModelService<DigitalSignageDeviceSettings>
>('DigitalSignageDeviceSettings');
export const DsMedias = new InjectionToken<ModelService<Media[]>>(
  'DigitalSignageMedias'
);
export const DsTeam = new InjectionToken<ModelService<DigitalSignageTeam[]>>(
  'DigitalSignageTeam'
);
// #endregion

export const StringModelService = new InjectionToken<ModelService<string>>(
  'StringModelService'
);

export const ProblemsService = new InjectionToken<
  ModelService<Collection<Problem>>
>('ProblemsService');
