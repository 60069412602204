<div class="container-fluid m-0">
  <div class="card">
    <div class="card-body" align="center">
      <img width="200" [src]="logo" />
    </div>
  </div>
</div>

<div class="container">
  <div class="card mt-3">
    <div class="card-body">
      <div class="px-4">
        <h3>Hi {{ firstName }}! Nice to meet you!</h3>
        <vg-player class="mt-3" (onPlayerReady)="onPlayerReady($event)">
          <vg-controls vgForwards="false">
            <vg-play-pause></vg-play-pause>
            <vg-playback-button></vg-playback-button>

            <vg-time-display
              vgProperty="current"
              vgFormat="mm:ss"></vg-time-display>

            <vg-scrub-bar>
              <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            </vg-scrub-bar>

            <vg-time-display
              vgProperty="left"
              vgFormat="mm:ss"></vg-time-display>
            <vg-time-display
              vgProperty="total"
              vgFormat="mm:ss"></vg-time-display>

            <vg-mute></vg-mute>
            <vg-volume></vg-volume>

            <vg-fullscreen></vg-fullscreen>
          </vg-controls>
          <vg-overlay-play></vg-overlay-play>
          <vg-buffering></vg-buffering>

          <video
            preload="auto"
            #media
            [vgMedia]="$any(media)"
            [src]="videoSource"
            (timeupdate)="onUpdateTime($event)"></video>
        </vg-player>
      </div>

      <div
        class="mt-4"
        align="center"
        *ngIf="ctaAction !== undefined && ctaAction !== ''">
        <a class="btn btn-primary" [href]="ctaAction" target="_blank">
          {{ ctaTitle }}
        </a>
      </div>
    </div>
  </div>
</div>
<br />

<div class="mt-5">
  <footer class="footer start-0">
    <div class="text-center">
      <span class="text-muted">Powered by TSOLife Sales Platform</span>
    </div>
  </footer>
</div>
