import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './application.component.html',
  styles: [],
})
export class ApplicationComponent implements OnInit {
  ngOnInit(): void {
    localStorage.removeItem('apiLoaded');
    if (environment.production) {
      this.addScriptToHead();
      this.addScriptToBody();
    }
  }

  addScriptToBody() {
    const script1 = document.createElement('script');
    script1.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'UA-64968384-1');
    `;

    const script2 = document.createElement('script');
    script2.async = true;
    script2.src = 'https://www.googletagmanager.com/gtag/js?id=UA-64968384-1';

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }

  addScriptToHead() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = '//js.hs-scripts.com/2866726.js';
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }
}
