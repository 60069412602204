import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ApiResponse } from '@models/common/api-response.model';
import { CommonApiService } from '@services/common/common-api.service';
import { CommonModule } from '@angular/common';
import { SubscriptionStatusPipe } from '@tso-pipes/subscription-status.pipe';
import { ToastrService } from 'ngx-toastr';
import { alertFire } from '@functions/alerts';

@Component({
  selector: 'app-unsubscribe',
  standalone: true,
  imports: [CommonModule, SubscriptionStatusPipe],
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
  providers: [
    CommonApiService,
    {
      provide: 'API_SERVICE',
      useValue: '',
    },
  ],
})
export class UnsubscribeComponent implements OnInit {
  userId: string;
  subscribed: boolean;
  statusLoaded = false;

  constructor(
    private service: CommonApiService,
    private route: ActivatedRoute,
    private router: Router,
    protected toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.userId = this.route.snapshot.paramMap.get('userId')!;
    this.getStatus();
  }

  getStatus() {
    const body = {
      userId: this.userId,
    };

    this.service
      .post<{ subscribed: boolean }>(`subscriptions/status`, body)
      .subscribe({
        next: r => {
          this.subscribed = r.subscribed;
          this.statusLoaded = true;
        },
        error: () => {
          this.router.navigate(['404']).then();
        },
      });
  }

  onChangeConfirm() {
    if (!this.subscribed) {
      this.onChangeSubscription();
      return;
    }

    alertFire('').then(result => {
      if (result.value) {
        this.onChangeSubscription();
      }
    });
  }

  onChangeSubscription() {
    const body = {
      userId: this.userId,
      subscribeInd: this.subscribed ? 0 : 1,
    };

    this.service.post<ApiResponse>(`subscriptions/update`, body).subscribe({
      next: r => {
        this.toastr.success(r?.message || 'Changes applied.');
        this.ngOnInit();
      },
      error: r => {
        this.toastr.error(r?.error.message || 'An error occurred.');
      },
    });
  }
}
