<div class="container-fluid m-0">
  <div class="card">
    <div class="card-body" align="center">
      <img
        width="200"
        src="https://s3.amazonaws.com/dev.tsolife.com/2023-11-17/6556e78152725.png" />
    </div>
  </div>
</div>

<div class="container">
  <div class="card mt-3 py-5">
    <div class="card-body text-center">
      <div *ngIf="statusLoaded">
        <div *ngIf="subscribed; else unsubscribed">
          <h4>Do you want to <b>unsubscribe</b> from our messages?</h4>
          <p>You will stop receiving the messages we send.</p>
        </div>
        <ng-template #unsubscribed>
          <h4>Do you want to <b>subscribe</b> to our messages?</h4>
          <p>You will start receiving the messages we send.</p>
        </ng-template>

        <button
          class="btn"
          [ngClass]="(subscribed | subscriptionStatus).class"
          (click)="onChangeConfirm()">
          {{ (subscribed | subscriptionStatus).button }}
        </button>
      </div>
    </div>
  </div>
</div>
<br />

<div class="mt-5">
  <footer class="footer start-0">
    <div class="text-center">
      <span class="text-muted">Powered by TSOLife Sales Platform</span>
    </div>
  </footer>
</div>
