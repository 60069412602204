import {
  ICommunityService,
  ICorporationModelService,
} from '@tokens/injection.tokens';
import { Injectable, InjectionToken, inject } from '@angular/core';
import { IdValue } from '@models/common/id-value.model';
import { isEmpty } from 'lodash';

export const IRegexService = new InjectionToken<RegexService>('RegexService');
@Injectable()
export class RegexService {
  corporation$ = inject(ICorporationModelService);
  community$ = inject(ICommunityService);

  private words: IdValue[] = [];

  constructor() {
    console.log('RegexService');
    if (this.storage.length) {
      this.words = [...this.words, ...this.storage];
    }
  }

  set(word: IdValue) {
    this.words = this.words.filter(x => x.id !== word.id);
    this.words.push(word);
    this.setStorage();
  }

  get(key: string) {
    return this.words.find(x => x.id === key);
  }
  get storageIndex() {
    let path = 'regex-service';
    if (this.corporation$.model) path = `regex-${this.corporation$.model.id}`;
    else if (this.community$.model) path = `regex-${this.community$.model.id}`;
    return path;
  }

  get storage() {
    const storage = localStorage.getItem(this.storageIndex);
    return storage ? (JSON.parse(storage) as IdValue[]) : [];
  }

  clearStorage(): void {
    localStorage.removeItem(this.storageIndex);
  }

  setStorage() {
    this.clearStorage();
    if (!isEmpty(this.words))
      localStorage.setItem(this.storageIndex, JSON.stringify(this.words));
  }
}
