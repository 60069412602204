import { Injectable, inject } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { CommonVerbsApiService } from '@services/common/common-verbs-api.service';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class ModelService<T> {
  private api = inject(CommonVerbsApiService);

  private subject$: ReplaySubject<T | null> = new ReplaySubject<T | null>(1);
  readonly model$: Observable<T | null> = this.subject$.asObservable();
  private state: T | null = null;

  isLoading = false;

  get model(): T | null {
    return this.state;
  }

  set(model: T | null) {
    this.state = model;
    this.subject$.next(this.state);
  }

  load(path: string, params: any = null, fn: ((n: T) => T) | null = null) {
    of(this.model)
      .pipe(
        switchMap(data => (data ? of(data as T) : this.api.get(path, params)))
      )
      .subscribe(response => {
        if (fn) {
          this.set(fn(response as T));
        } else {
          this.set(response as T);
        }
      });
  }
}
