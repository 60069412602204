import { ApplicationModule } from './app/application/application.module';
import { enableProdMode } from '@angular/core';
import { environment } from '@environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  /*
  window.console.log = () => {
    //
  };*/
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(ApplicationModule)
  .catch(() => {
    //TODO:
  });
