import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, inject } from '@angular/core';
import { CommonApiService } from '@services/common/common-api.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-redirection',
  template: '',
  standalone: true,
  imports: [CommonModule],
  providers: [
    CommonApiService,
    {
      provide: 'API_SERVICE',
      useValue: '',
    },
  ],
})
export class SurveyRedirectionComponent implements OnInit {
  api = inject(CommonApiService);
  route = inject(ActivatedRoute);
  router = inject(Router);
  baseUrl = window.location.origin;

  ngOnInit(): void {
    const paramCode = this.route.snapshot.paramMap.get('code');

    this.api
      .get<{ redirectUrl: string }>(
        `helper/get-redirect-route?shortUrl=${this.baseUrl}/sv/${paramCode}`
      )
      .subscribe(
        r => {
          window.location.href = r.redirectUrl;
        },
        error => {
          this.router.navigate(['404']).then();
        }
      );
  }
}
